import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';
import dayjs from 'dayjs';
import {
  getDriverDataLogReq,
  getDriverDataCarrierLogReq,
  getDriverLogDate,
  getDriverLogListReq,
} from '../../../actions/driver_log';
import arrowRight from '../../../img/arrow-right.svg';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { LogTabs } from './LogTabs/LogTabs';
import { LogTopPanel } from './logs-panels/LogTopPanel';
import { LogViolationPanel } from './logs-panels/LogViolationPanel';
import { LogBottomPanel } from './logs-panels/LogBottomPanel';
import { LogDashboardPanel } from './logs-panels/LogDashboardPanel';
import ChartTestV from './LogTabs/Chart/ChartTestV';
import { getDriverReq, getLogForm, getLogTransactions } from '../../../actions';
import { getTzValue } from '../../../utils/utils';
import MainContainer from '../../layout/MainContainer';
import MainHeader from '../../header/MainHeader';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ChartDalabels } from './LogTabs/Chart/interface/ChartInterface';
import { iteratee } from 'lodash';
import Spiner from '../../spiner/Spiner';
import { motion } from 'framer-motion';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export interface IChoosed {
  id: string;
  color: string;
}

export const DriverLogList: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const driverLog = useSelector((state: any) => state.driverLog);
  const driver = useSelector((state: any) => state.driver.driver);
  const tzValue = driver?.terminal?.tz_value;
  const tz = getTzValue(tzValue);
  const driverLogs = useSelector((state: any) => state.driverLog?.driverData);
  const logForms = useSelector((state: any) => state.driverLog?.logForms);
  const driverLogDate = useSelector(
    (state: any) => state.driverLog?.driverLogDate,
  );
  const params = useParams();
  React.useEffect(() => {
    const day = dayjs(driverLogDate).tz(tz);
    if (params.driverid && driverLogDate != null) {
      dispatch(
        getDriverDataLogReq({
          driverId: params.driverid,
          date: day.format('YYYY-MM-DD'),
          queryParams: {
            with: ['terminal', 'group', 'carrier', 'documents'],
          },
          onSuccess: (carrierId: any) => {
            dispatch(
              getDriverDataCarrierLogReq({
                carrierId: carrierId,
                queryParams: {
                  with: ['vehicles', 'devices', 'trailers', 'drivers'],
                },
              }),
            );
          },
        }),
      );
    }
  }, [driverLogDate]);
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  useEffect(() => {
    dispatch(
      getDriverReq({
        driverId: params.driverid,
        queryParams: {
          with: ['terminal', 'group', 'carrier', 'documents'],
        },
      }),
    );
  }, []);

  // useEffect(() => {
  //   if (driver && tz && tzValue) {
  //     const time = dayjs().tz(tz).format('YYYY-MM-DD');
  //     dispatch(getDriverLogDate(time));
  //   }
  // }, [tzValue, tz]);
  const { checkPermission } = usePermissions();

  const [choosed, setChoosed] = useState<IChoosed>({
    id: '',
    color: '',
  });
  const rowRefs = useRef<{ [key: string]: HTMLTableRowElement | null }>({});
  useEffect(() => {
    if (rowRefs.current) {
      Object.keys(rowRefs.current).forEach((key) => {
        const rowNode = rowRefs.current[key];
        if (rowNode) {
          if (key === choosed.id) {
            rowNode.classList.add(`color-${choosed.color.slice(1)}`);
          } else {
            rowNode.classList.remove('active');
          }
        }
      });
      rowRefs.current[choosed.id]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [choosed, rowRefs]);
  React.useEffect(() => {
    if (driverLogDate && params?.driverid?.length != 0) {
      dispatch(
        getDriverLogListReq({
          queryParams: {
            with: ['driver_groups', 'vehicles', 'drivers', 'vehicle'],
          },
          driverid: params?.driverid,
          date: driverLogDate,
        }),
      );
      dispatch(
        getLogTransactions({
          id: params?.driverid,
          queryParams: {
            with: ['count', 'events', 'driver', 'unit'],
          },
        }),
      );
    }
  }, [driverLogDate, params?.driverid]);
  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setLoading(true);
      }, 1000);
    }
  }, [loading]);

  const [datalabels, setDataLabels] = useState<ChartDalabels[]>([]);

  const trailers =
    logForms?.[logForms?.length - 1]?.trailer?.[0].length != 0
      ? logForms?.[logForms?.length - 1]?.trailer
      : [];

  return (
    <>
      {checkPermission(AllPermissionsType.VEHICLE_LIST) ? (
        <>
          {!loading && (
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'white',
                zIndex: 998,
              }}
            >
              <Spiner />
            </div>
          )}
          <MainContainer>
            <Row
              style={{
                overflowY: 'scroll',
                overflowX: 'hidden',
                height: '80vh',
              }}
            >
              <Col span={24}>
                <MainHeader
                  titles={[
                    {
                      text: `Logs (${driverLogs?.driver?.first_name} ${driverLogs?.driver?.last_name} - ${driverLogs?.driver?.username})`,
                    },
                    {
                      text: 'Profile',
                    },
                  ]}
                  back={true}
                  link="/client/logs"
                  trailer={trailers}
                />
              </Col>
              <Col
                className="sticky-important"
                span={24}
                style={{
                  background: 'white',
                  top: '0',
                  zIndex: 997,
                }}
              >
                <Col span={24}>
                  <LogTopPanel setLoading={setLoading} />
                </Col>
                <Col span={24}>
                  <ChartTestV
                    datalabels={datalabels}
                    setDataLabels={setDataLabels}
                    choosed={choosed.id}
                    setChoosed={setChoosed}
                  />
                </Col>
              </Col>
              <Col span={24}>
                <LogViolationPanel />
              </Col>
              <Col span={24}>
                <LogBottomPanel />
              </Col>
              <Col span={24}>
                <LogDashboardPanel />
              </Col>
              <Col span={24}>
                <LogTabs rowRefs={rowRefs} datalabels={datalabels} />
              </Col>
            </Row>
          </MainContainer>
        </>
      ) : (
        <NoPermission />
      )}
    </>
  );
};

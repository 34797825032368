import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  Table,
  Dropdown,
  Row,
  Col,
  Select,
  Button,
  Tooltip,
  DatePicker,
  Space,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTableParams } from '../../../hooks/useTableParams';
import dayjs from 'dayjs';
import { defaultTo } from 'lodash';

import customParseFormat from 'dayjs/plugin/customParseFormat';

import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { CreateDriverLogModal } from './CreateLogModal';
import fixLogIcon from '../../../img/fixLogIcon.svg';
import RevertIcon from '../../../img/fivLogIcon.svg';
import RebootIcon from '../../../img/rebootIcon.svg';
import edit from '../../../img/edit.svg';
import copyAlt from '../../../img/copy-alt.svg';

import trash from '../../../img/trash.svg';

import { LogBulkPanel } from './logs-panels/LogBulk';
import {
  getEventLabel,
  getOriginLabel,
  parseDateGeneralStringFormat,
  secondsToHMS,
} from './log-utils';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  deleteDriverLogReq,
  getDriverLogListReq,
  getLogTransactions,
  getLogTransactionsSucces,
} from '../../../actions';
import { EditDriverLogModal } from './EditLogModal';
import { ChartDalabels, Root2 } from './LogTabs/Chart/interface/ChartInterface';
import { CopyEventModal } from './CopyEventModal';
import {
  CheckCircleOutlined,
  ReloadOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  chartFromDateToTimeStamp,
  getCurrentDate,
} from './LogTabs/Chart/utils/ChartDateForrmatter';
import {
  applyDriverReq,
  forceAproveReq,
  transactionRolllBackReq,
} from '../../../actions/transaction';
import { getTzValue } from '../../../utils/utils';
import { annotations } from './fields/log-contant';
import CreateRecordModal, { IDriverInfo } from '../dot/CreateRecordModal';
import Chip from '../../chip/Chip';
import { CHARTCOLORS } from './constant';
import Spiner from '../../spiner/Spiner';
import TableSpinner from '../../spiner/TableSpinner';

const { RangePicker } = DatePicker;

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
export const LogTabelPanel = ({
  datalabels,
  rowRefs,
}: {
  datalabels: ChartDalabels[];
  rowRefs: React.MutableRefObject<{
    [key: string]: HTMLTableRowElement | null;
  }>;
}) => {
  const driverLog = useSelector((state: any) => state.driverLog);
  const tzValue = driverLog?.driverData?.driver?.terminal?.tz_value;
  const tz = getTzValue(tzValue);
  const dispatch = useDispatch();
  const { handleTableChange, tableParams, rowSelection, clearOrder } =
    useTableParams({ pageSizeMore: 100, pageSizeArr: [100, 150, 200] });
  const logs = useSelector((state: any) => state.driverLog.logList);
  const driverLogDate = useSelector(
    (state: any) => state?.driverLog?.driverLogDate,
  );
  const logForms = useSelector((state: any) => state.driverLog.logForms);
  const params = useParams();
  const count = useSelector((state: any) => state.log.count);
  const loading = useSelector((state: any) => state.driverLog.loading);
  const trasactions = useSelector((state: any) => state?.log?.trasactions);

  const formattedLogs = logs?.map((log: any) => ({
    ...log,
    timestamp: dayjs(log.timestamp).tz(tz).valueOf(),
  }));
  const DurationTime = ({
    time,
  }: {
    time: {
      hours: number;
      minutes: number;
      seconds: string;
    };
  }) => {
    const { hours, minutes, seconds } = time;
    return (
      <div>
        {!isNaN(hours) && !isNaN(minutes) && (
          <>
            {hours.toString().length != 2 ? `0${hours}` : hours}:
            {minutes.toString().length != 2 ? `0${minutes}` : minutes}:
            {seconds.toString().length != 2 ? `0${seconds}` : seconds}
          </>
        )}
      </div>
    );
  };
  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Date & Time',
      key: 'identificator_log',
      dataIndex: 'identificator',
      render: (name, record, index) => {
        return (
          <div style={{ marginLeft: 10 }}>
            {dayjs(record.timestamp * 1000)
              .tz(tz)
              .format('MM/DD/YYYY HH:mm:ss')}
          </div>
        );
      },
      width: '12%',
      ellipsis: true,
    },
    {
      title: 'Duration',
      key: 'duration',
      dataIndex: 'duration',
      render: (name, record, index) => {
        const data = datalabels.filter((label) => label.id === record.id);
        const duration = {
          hours: data?.[0]?.hour,
          minutes: data?.[0]?.minute,
          seconds: `${data?.[0]?.seconds}`,
        };
        return <DurationTime time={duration} />;
      },
      width: '8%',
      ellipsis: true,
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      width: '8%',
      ellipsis: true,
      render: (value, record, index) => {
        const colorEvent: any = {
          OFF: CHARTCOLORS.OFF,
          SB: CHARTCOLORS.SB,
          D: CHARTCOLORS.D,
          ON: CHARTCOLORS.ON,
          Intermediate: 'green',
          'Intermediate (RLP)': 'green',
          YM: CHARTCOLORS.ON,
          'PC/YM Cleared': 'green',
          'Certification (1)-MM/DD/YYYY': 'blue',
          'Certification (2 - 9)- MM/DD/YYYY': 'blue',
          Login: 'green',
          Logout: 'red',
          'Power-up': 'green',
          'Shut-down': 'red',
          'Shut-Down (RLP)': 'red',
          Malfunction: 'red',
          'Malfunction cleared': 'red',
          'Power data': 'green',
          'Power data cleared': 'green',
          PC: CHARTCOLORS.OFF,
        };

        return (
          <Tooltip
            title={getEventLabel(record?.event_type, record?.event_code)}
          >
            <div className="ubuntu">
              <Chip
                color={
                  colorEvent[
                    getEventLabel(record?.event_type, record?.event_code)
                  ]
                }
              >
                {
                  getEventLabel(record?.event_type, record?.event_code)?.split(
                    ' ',
                  )[0]
                }
              </Chip>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: '15%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.location ? `${record?.location}` : ''}
          </div>
        );
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: '8%',
      ellipsis: true,
      render: (value, record, index) => {
        annotations;
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {annotations[record.note]?.value || record.note}
          </div>
        );
      },
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: 'vehicle',
      width: '9%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {logForms[0]?.vehicle?.identificator}
          </div>
        );
      },
    },

    {
      title: 'Odometer',
      dataIndex: 'total_miles',
      key: 'total_miles',
      width: '7%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {`${defaultTo(record?.total_miles, '')}`}
          </div>
        );
      },
    },
    {
      title: 'EH',
      dataIndex: 'total_hours',
      key: 'total_hours',
      width: '5%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.total_hours?.toFixed(1)}
          </div>
        );
      },
    },
    {
      title: 'Origin',
      dataIndex: 'origin',
      key: 'origin',
      width: '6%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {getOriginLabel(
              record?.event_type,
              record?.event_code,
              record?.record_origin,
            )}
          </div>
        );
      },
    },

    {
      title: 'Edit',
      dataIndex: 'edit',
      key: 'edit',
      width: '5%',
      ellipsis: true,
      render: (value, record, index) => {
        const day = dayjs(record.timestamp * 1000)
          .tz(tz)
          .format('YYYY-MM-DD');

        return (
          <div className="ubuntu" style={{ display: 'flex' }}>
            {day.includes(driverLogDate) && (
              <>
                <EditDriverLogModal log={record}>
                  <div>
                    <img
                      style={{ width: 12, cursor: 'pointer', marginRight: 10 }}
                      src={edit}
                      alt={'edit'}
                    />
                  </div>
                </EditDriverLogModal>
                <div>
                  <img
                    style={{
                      width: 12,
                      cursor: 'pointer',
                      marginRight: 10,
                      color: 'red',
                    }}
                    src={trash}
                    alt={'delete'}
                    onClick={() => {
                      dispatch(
                        deleteDriverLogReq({
                          logId: record?.id,
                          onSuccess: () => {
                            dispatch(
                              getDriverLogListReq({
                                queryParams: {
                                  with: [
                                    'driver_groups',
                                    'vehicles',
                                    'drivers',
                                    'vehicle',
                                    'driver',
                                    'codriver',
                                  ],
                                },
                                driverid: params?.driverid,
                                date: driverLogDate,
                              }),
                            );
                          },
                        }),
                      );
                    }}
                  />
                </div>
                <div>
                  <CopyEventModal record={record}>
                    <img
                      style={{ width: 12, cursor: 'pointer', marginRight: 10 }}
                      src={copyAlt}
                      alt={'copy'}
                    />
                  </CopyEventModal>
                </div>
              </>
            )}
          </div>
        );
      },
    },

    {
      title: 'Seq #',
      dataIndex: 'log_id',
      key: 'log_id',
      // sortOrder: getOrderFromTableParams("vin", tableParams),
      // sorter: {
      //   compare: (a: any, b: any) => a.email - b.email,
      //   multiple: 5,
      // },
      render: (name, record, index) => {
        // return <div>{`${record?.id}`}</div>;
        return <div>{`${record?.sequence_id ? record?.sequence_id : ''}`}</div>;
      },
      ellipsis: true,
      width: '5%',
    },

    {
      title: 'Progress',
      dataIndex: 'progress',
      // sortOrder: getOrderFromTableParams("status", tableParams),
      key: 'progress',
      // sorter: {
      //   compare: (a: any, b: any) => a.mcnumber - b.mcnumber,
      //   multiple: 5,
      // },
      width: '7%',
      ellipsis: true,
      render: (value, record, index) => {
        const recordStatus: any = {
          1: 'Active',
          2: 'Changed',
          3: 'Pending',
          4: 'Rejected',
        };

        const recordColors: any = {
          1: '#69CE46',
          2: '#6F56B6',
          3: '#374957',
          4: '#FD371F',
        };

        return (
          <div>
            <Chip color={recordColors[record.record_status]}>
              {record?.record_status ? recordStatus[record.record_status] : ''}
            </Chip>
          </div>
        );
      },
      filters: [
        { key: '1', value: 'Active' },
        { key: '2', value: 'Changed' },
        { key: '3', value: 'Pending' },
        { key: '4', value: 'Rejected' },
      ].map((st: any) => {
        return {
          text: st.value,
          value: st.key,
        };
      }),
      filteredValue: tableParams?.filters?.status || null,
    },
  ];
  const { checkPermission } = usePermissions();

  const setRowRef = (id: string, node: HTMLTableRowElement | null) => {
    if (node) {
      rowRefs.current[id] = node;
    }
  };
  const submitClick = () => {
    dispatch(
      applyDriverReq({
        id: params.driverid,
      }),
    );
    setTimeout(() => {
      dispatch(
        getLogTransactions({
          id: params?.driverid,
          queryParams: {
            with: ['count', 'events', 'driver', 'unit'],
          },
        }),
      );
    }, 300);
  };
  const finishClick = () => {
    if (trasactions[trasactions.length - 1].id) {
      dispatch(
        forceAproveReq({
          id: trasactions[trasactions.length - 1].id,
        }),
      );
    }
  };
  const rollBackClick = () => {
    if (trasactions[trasactions.length - 1].id) {
      dispatch(
        transactionRolllBackReq({
          id: trasactions[trasactions.length - 1].id,
        }),
      );
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [driverInfo, setDriverInfo] = useState<IDriverInfo>({
    carrier: driverLog?.driverData?.carrier?.id,
    driver: driverLog?.driverData?.driver?.id,
  });
  useEffect(() => {
    setDriverInfo({
      carrier: driverLog?.driverData?.carrier?.id,
      driver: driverLog?.driverData?.driver?.id,
    });
  }, [driverLog]);
  return (
    <>
      {checkPermission(AllPermissionsType.VEHICLE_LIST) ? (
        <>
          <Row style={{ width: '100%', justifyContent: 'space-between' }}>
            <Col
              style={{
                display: 'flex',
                // justifyContent: "flex-end",
                alignItems: 'center',
              }}
            >
              <LogBulkPanel />
            </Col>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // padding: "10px 0px",
                  justifyContent: 'space-between',
                  width: '100%',
                  gap: 5,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={clearOrder}
                >
                  <div className="ubuntu orange">
                    <CreateDriverLogModal />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={clearOrder}
                >
                  <div
                    className="ubuntu"
                    style={{
                      background: '#f5f9ff',
                      fontWeight: 700,
                      borderRadius: 10,
                    }}
                  >
                    <CreateRecordModal
                      option={driverInfo}
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 0px',
                }}
              >
                <Tooltip title="Fix Logs">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: 10,
                      borderRadius: 10,
                      gap: 10,
                      background: '#f5f9ff',
                      cursor: 'pointer',
                    }}
                  >
                    <img src={fixLogIcon} width={18} height={18} />
                    <div
                      style={{
                        fontWeight: 'bold',
                        color: '#5e6874',
                      }}
                    >
                      Fix
                    </div>
                  </div>
                </Tooltip>
                <Tooltip title="Revert">
                  <div
                    onClick={rollBackClick}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: 10,
                      marginLeft: 40,
                      borderRadius: 10,
                      background: '#f5f9ff',
                      cursor: 'pointer',
                    }}
                  >
                    <img src={RebootIcon} width={22} />
                  </div>
                </Tooltip>

                <Tooltip title="Submit Transactions">
                  <div
                    onClick={submitClick}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: 10,
                      marginLeft: 10,
                      borderRadius: 10,
                      background: '#f5f9ff',
                      cursor: 'pointer',
                      position: 'relative',
                      gap: 10,
                    }}
                  >
                    <img src={RevertIcon} width={22} />
                    {trasactions[trasactions.length - 1]?.status_value !=
                      'ToApprove' && (
                      <div
                        style={{
                          background: '#ffab00',
                          borderRadius: 10,
                          color: 'white',
                          width: 15,
                          height: 15,
                          position: 'absolute',
                          top: -5,
                          right: -5,
                          display: 'flex',
                          justifyContent: 'center',
                          fontSize: 11,
                          alignItems: 'center',
                        }}
                        className="ubuntu"
                      >
                        {trasactions[trasactions.length - 1]?.count}
                      </div>
                    )}
                  </div>
                </Tooltip>
              </div>
            </Col>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip title="Finish">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 10,
                    marginRight: 10,
                    gap: 10,
                    borderRadius: 10,
                    background: '#f5f9ff',
                    cursor: 'pointer',
                  }}
                  onClick={finishClick}
                >
                  <CheckCircleOutlined
                    style={{ fontSize: '22px', color: '#69CE46' }}
                  />
                  <p
                    style={{
                      marginRight: 0,
                      color: '#69CE46',
                    }}
                  >
                    Finish
                  </p>
                </div>
              </Tooltip>
            </Col>
          </Row>
          <div style={{ width: '100%' }} className="logs-table">
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={formattedLogs}
              pagination={{
                ...tableParams.pagination,
                position: ['bottomCenter'],
                total: defaultTo(formattedLogs?.length, count),
              }}
              onChange={handleTableChange}
              rowSelection={{ ...rowSelection, columnWidth: 10 }}
              rowClassName={'simple'}
              loading={{
                spinning: loading,
                indicator: <TableSpinner />,
              }}
              components={{
                body: {
                  row: (props: any) => {
                    const { children, className, ...restProps } = props;
                    const record = props['data-row-key'];
                    return (
                      <tr
                        {...restProps}
                        ref={(node) => setRowRef(record, node)}
                        className={className}
                      >
                        {children}
                      </tr>
                    );
                  },
                },
              }}
              className="table-custom"
              //   sticky
              //   scroll={{ y: window.innerHeight - 235 }}
            />
          </div>
        </>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
